import mathAverage from 'math-avg';
import moment from 'moment';

function Calculate(response, StationIDs) {
    const groupBy = (arr) => arr.reduce((acc, ele)=>( (acc[ele.DateTime.split(' ')[0]] = acc[ele.DateTime.split(' ')[0]] || []).push(ele), acc),{})
    const max  =  (arr) => Math.round(Math.max(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    const min  =  (arr) => Math.round(Math.min(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    const avg = (arr) => Math.round(mathAverage(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    
    return Object.entries(groupBy(response.data.data)).map(([key, val])=> ({date:moment(key), min:min(val), max: max(val), avg: avg(val)}))
}

export default Calculate;