import moment from "moment"

function AddDailyTimeSeries() {
    var dailySeries = [];
    var startTime = moment()
    .startOf("day")

    const roundedUp = Math.ceil(moment().minute() / 5) * 5;
    var endTime = moment().minute(roundedUp).second(0);

    while(startTime <= endTime) {
        dailySeries.push(new moment(startTime).format('DD. MM. YYYY HH:mm'));
        startTime.add(5, 'minutes');
    }

    return dailySeries;
}

function AddWeeklyTimeSeries() {

}

function AddMonthlyTimeSeries() {

}

export {
    AddDailyTimeSeries,
    AddWeeklyTimeSeries,
    AddMonthlyTimeSeries
}