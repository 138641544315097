import Clear from "../../Stats/Clear/Clear";
import moment from 'moment';
import Parameter from "./Parameter";
import $ from 'jquery';

function GetDailyFormatter() {
    let tool = {
        formatter: function() {
            var s = '<b>'+ moment(this.x).format('DD. MM. YYYY HH:mm') +'</b>';
    
            $.each(this.points, function(i, point) {
                    s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
            });
    
            return s;
        },
        shared: true
    }
    return tool;
}

function SodrazicaGraphs(response, StationSettings, ChartOptions, TableSettings) {
    var parameter = new Parameter(); 

    Clear(StationSettings.ChartSettings.Temp)
    Clear(StationSettings.ChartSettings.Humidity)
    Clear(StationSettings.ChartSettings.Pressure)
    Clear(StationSettings.ChartSettings.Rain)
    Clear(StationSettings.ChartSettings.Wind)
    Clear(StationSettings.ChartSettings.Dewpoint)

    StationSettings.ChartSettings.Temp.series.push({ data: [], name: 'Temperatura', color: 'red' });
    StationSettings.ChartSettings.Humidity.series.push({ data: [], name: 'Vlaga', color: 'green' });
    StationSettings.ChartSettings.Rain.series.push({ data: [], name: 'Padavine', color: 'blue' });
    StationSettings.ChartSettings.Wind.series.push({ data: [], name: 'Sunki vetra', color: 'black' }, { data: [], name: 'Hitrost vetra', color: 'gray' });
    StationSettings.ChartSettings.Dewpoint.series.push({ data: [], name: 'Rosišče', color: 'orange' });

    StationSettings.ChartSettings.Temp.series.length = 1;
    StationSettings.ChartSettings.Humidity.series.length = 1;
    StationSettings.ChartSettings.Rain.series.length = 1;
    StationSettings.ChartSettings.Wind.series.length = 2;
    StationSettings.ChartSettings.Dewpoint.series.length = 1;

    console.log(ChartOptions.yAxis.tickPositioner)
    StationSettings.ChartSettings.Temp.yAxis.tickPositioner = ChartOptions.yAxis.tickPositioner;
    StationSettings.ChartSettings.Temp.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Humidity.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Rain.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Wind.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Dewpoint.xAxis = ChartOptions.xAxis;
    
    let data = response.data;
    StationSettings.ChartSettings.Temp.series[0].data = data.map((x) => x.temperature);
    //StationSettings.ChartSettings.Temp.xAxis.categories = data.map((x) => moment(x.modified).format('DD. MM. Y. HH:mm'));
    StationSettings.ChartSettings.Temp.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Humidity.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Rain.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Wind.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Dewpoint.tooltip = GetDailyFormatter();

    StationSettings.LastRefresh = moment(StationSettings.ChartSettings.Temp.xAxis.categories[StationSettings.ChartSettings.Temp.xAxis.categories.length - 1]).format('DD. MM. Y HH:mm');

    StationSettings.ChartSettings.Humidity.series[0].data = data.map((x) => x.humidity);
    StationSettings.ChartSettings.Humidity.xAxis.categories = data.map((x) => moment(x.modified));

    StationSettings.ChartSettings.Rain.series[0].data = data.map((x) => x.precipTotal);
    StationSettings.ChartSettings.Rain.xAxis.categories = data.map((x) => moment(x.modified));

    StationSettings.ChartSettings.Wind.series[0].data = data.map(x => x.gust);
    StationSettings.ChartSettings.Wind.series[1].data = data.map(x => x.wind);

    StationSettings.ChartSettings.Dewpoint.series[0].data = data.map((x) => x.dewpoint);
    StationSettings.ChartSettings.Dewpoint.xAxis.categories = data.map((x) => moment(x.modified));

    StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
        return moment(String(this.value)).format('HH:mm');
    }

    StationSettings.DetailsSettings = TableSettings;
    parameter.GetStats(response, StationSettings.DetailsSettings.temperature, 'temperature');
    parameter.GetStats(response, StationSettings.DetailsSettings.humidity, 'humidity');
    parameter.GetRainStats(response, StationSettings.DetailsSettings.rain, 'precipTotal');
    parameter.GetWindStats(response, StationSettings.DetailsSettings.windSpeed, 'wind', 'gust');
    parameter.GetStats(response, StationSettings.DetailsSettings.dewpoint, 'dewpoint');
}

export {
    SodrazicaGraphs
}