import moment from "moment";

class Parameter {
  GetStats(response, data, parameter) {
    var hribTemp = response.data
      .map((x) => x[`${parameter}`]).filter(Number);

      data.value = hribTemp[hribTemp.length - 1];
        data.minValue = Math.min(...hribTemp);
        data.maxValue = Math.max(...hribTemp);
        data.avgValue = hribTemp.reduce((a, b) => a + b, 0) / hribTemp.length || 0;
        data.avgValue = Math.floor(data.avgValue * 10) / 10;
    
        var minTime = response.data.find(
          (x) => x[`${parameter}`] === data.minValue
        );
        var maxTime = response.data.find(
          (x) => x[`${parameter}`] === data.maxValue
        );

        data.minTime = moment(minTime.modified)
          .format("HH:mm (DD. MM.)");
        data.maxTime = moment(maxTime.modified)
          .format("HH:mm (DD. MM.)");
    
        data.minValue = Math.round(data.minValue * 10) / 10;
        data.maxValue = Math.round(data.maxValue * 10) / 10;



    return data;
  }
  GetSlemenaStats(response, data, parameterId, parameterMinId, parameterMaxId) {
    let responseData = response.data.observations;

    var hribTemp = responseData
      .map((x) => x.metric[`${parameterId}`])
      .filter(Number);

    data.value = Math.round(hribTemp[hribTemp.length - 1] * 10) / 10;
    data.minValue = Math.min(
      ...responseData.map((x) => x.metric[`${parameterMinId}`])
    );
    data.maxValue = Math.max(
      ...responseData.map((x) => x.metric[`${parameterMaxId}`])
    );
    data.avgValue = hribTemp.reduce((a, b) => a + b, 0) / hribTemp.length || 0;
    data.avgValue = Math.floor(data.avgValue * 10) / 10;

    var minTime = responseData.find(
      (x) => x.metric[`${parameterMinId}`] === data.minValue
    );
    var maxTime = responseData.find(
      (x) => x.metric[`${parameterMaxId}`] === data.maxValue
    );

    data.minTime = moment(minTime.obsTimeLocal).format("HH:mm (DD. MM.)");
    data.maxTime = moment(maxTime.obsTimeLocal).format("HH:mm (DD. MM.)");

    data.minValue = Math.round(data.minValue * 10) / 10;
    data.maxValue = Math.round(data.maxValue * 10) / 10;

    if (
      parameterId === "windspeedAvg" ||
      parameterId === "solarRadiationHigh"
    ) {
      data.minValue = data.maxValue;
      data.maxValue = data.value;
      data.avgValue = null;
    }

    if (parameterId === "windspeedAvg") {
      data.minValue = Math.round((data.minValue / 3.6) * 10) / 10;
    }

    return data;
  }
  GetSlemenaHumidityStats(
    response,
    data,
    parameterId,
    parameterMinId,
    parameterMaxId
  ) {
    let responseData = response.data;
    var hribTemp = responseData.map((x) => x[`${parameterId}`]).filter(Number);

    data.value = Math.round(hribTemp[hribTemp.length - 1] * 10) / 10;
    data.minValue = Math.min(
      ...responseData.map((x) => x[`${parameterMinId}`])
    );
    data.maxValue = Math.max(
      ...responseData.map((x) => x[`${parameterMaxId}`])
    );
    data.avgValue = hribTemp.reduce((a, b) => a + b, 0) / hribTemp.length || 0;
    data.avgValue = Math.floor(data.avgValue * 10) / 10;

    var minTime = responseData.find(
      (x) => x[`${parameterMinId}`] === data.minValue
    );
    var maxTime = responseData.find(
      (x) => x[`${parameterMaxId}`] === data.maxValue
    );

    data.minTime = moment(minTime.obsTimeLocal).format("HH:mm (DD. MM.)");
    data.maxTime = moment(maxTime.obsTimeLocal).format("HH:mm (DD. MM.)");

    data.minValue = Math.round(data.minValue * 10) / 10;
    data.maxValue = Math.round(data.maxValue * 10) / 10;

    return data;
  }
  GetMonthlyStats(response, parameterId, data) {
    var hribTemp = response.data.data
      .map((x) => x[`${parameterId}`])
      .filter(Number);

    data.value = Math.round(hribTemp[hribTemp.length - 1] * 10) / 10;
    data.minValue = Math.min(...hribTemp);
    data.maxValue = Math.max(...hribTemp);
    data.avgValue = hribTemp.reduce((a, b) => a + b, 0) / hribTemp.length || 0;
    data.avgValue = Math.floor(data.avgValue * 10) / 10;

    var minTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.minValue
    );
    var maxTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.maxValue
    );

    data.minTime = moment(minTime.DateTime)
      .format("HH:mm (DD. MM.)");
    data.maxTime = moment(maxTime.DateTime)
      .format("HH:mm (DD. MM.)");

    data.minValue = Math.round(data.minValue * 10) / 10;
    data.maxValue = Math.round(data.maxValue * 10) / 10;

    return data;
  }
  GetYearlyStats(response, parameterId, data) {
    var hribTemp = response.data.data
      .map((x) => x[`${parameterId}`])
      .filter(Number);

    data.value = Math.round(hribTemp[hribTemp.length - 1] * 10) / 10;
    data.minValue = Math.min(...hribTemp);
    data.maxValue = Math.max(...hribTemp);
    data.avgValue = hribTemp.reduce((a, b) => a + b, 0) / hribTemp.length || 0;
    data.avgValue = Math.floor(data.avgValue * 10) / 10;

    var minTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.minValue
    );
    var maxTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.maxValue
    );

    data.minTime = moment(minTime.DateTime).format("DD. MM.");
    data.maxTime = moment(maxTime.DateTime).format("DD. MM.");

    data.minValue = Math.round(data.minValue * 10) / 10;
    data.maxValue = Math.round(data.maxValue * 10) / 10;

    return data;
  }
  GetRainStats(response, data, parameterDayId) {
    var hribDay = response.data.map((x) => x[`${parameterDayId}`]);

    data.value = hribDay[hribDay.length - 1];
    data.minValue = hribDay[hribDay.length - 1];
    data.maxValue = "---";
    return data;
  }
  GetDetailsRainStats(response, parameterId, parameterDayId, data) {
    var hribDay = response.data.data
      .map((x) => x[`${parameterDayId}`])
      .filter(Number);

    data.value = hribDay[hribDay.length - 1];
    data.minValue = "---";
    data.maxValue = "---";

    return data;
  }
  GetWindStats(response, data, parameterId, parameterWindId) {
    var hribGust = response.data.map((x) => x[`${parameterId}`]);
    var hribWind = response.data.map((x) => x[`${parameterWindId}`]);

    data.value = hribWind[hribWind.length - 1];
    data.minValue = Math.max(...hribGust);
    data.maxValue = hribWind.reduce((a, b) => a + b, 0) / hribWind.length || 0;
    data.maxValue = Math.floor(data.maxValue * 10) / 10;
    data.maxTime = null;
    data.minTime = response.data.find(
      (x) => x[`${parameterId}`] === data.minValue
    );

    data.minTime = moment(data.minTime.DateTime)
      .format("HH:mm");

    return data;
  }
  GetMonthlyWindStats(response, parameterId, parameterWindId, data) {
    var hribGust = response.data.data
      .map((x) => x[`${parameterId}`])
      .filter(Number);
    var hribWind = response.data.data
      .map((x) => x[`${parameterWindId}`])
      .filter(Number);

    data.value = hribWind[hribWind.length - 1];
    data.minValue = Math.max(...hribGust);
    data.maxValue = hribWind.reduce((a, b) => a + b, 0) / hribWind.length || 0;
    data.maxValue = Math.floor(data.maxValue * 10) / 10;
    data.maxTime = null;
    data.minTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.minValue
    );
    data.minTime = moment(data.minTime.DateTime)
      .format("HH:mm (DD. MM.)");

    return data;
  }
  GetYearlyWindStats(response, parameterId, parameterWindId, data) {
    var hribGust = response.data.data
      .map((x) => x[`${parameterId}`])
      .filter(Number);
    var hribWind = response.data.data
      .map((x) => x[`${parameterWindId}`])
      .filter(Number);

    data.value = hribWind[hribWind.length - 1];
    data.minValue = Math.max(...hribGust);
    data.maxValue = hribWind.reduce((a, b) => a + b, 0) / hribWind.length || 0;
    data.maxValue = Math.floor(data.maxValue * 10) / 10;
    data.maxTime = null;
    data.minTime = response.data.data.find(
      (x) => x[`${parameterId}`] === data.minValue
    );
    data.minTime = moment(data.minTime.DateTime)
      .format("DD. MM.");

    return data;
  }
}

export default Parameter;
