import $ from 'jquery';
import moment from 'moment';
let filledTooltip = {
        formatter: function() {
            var s = '<b>'+ moment(this.x).format('DD. MM YYYY') +'</b>';
    
            $.each(this.points, function(i, point) {
                    s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
            });
    
            return s;
        },
        shared: true
}
export var ChartSettings = {
    Temperature: {
        chart: {
            height: 355,
            plotBackgroundColor: '#e1e8f5'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Temperatura °C'
            },
        },
        plotOptions: {
            series: {
                connectNulls: true
            },
            area: {
                backgroundColor: 'gray'
            }
        },
        series: [
            {
                name: 'Hrib',
                color: 'red',
                data: []
            },
            {
                name: 'Retje',
                color: 'blue',
                data: []
            }
        ],
    },
    Temp: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Temperatura °C'
            },
            tickPositioner: null
        },
        series: [
            {
                name: 'Temperatura',
                color: 'red',
                data: []
            },
        ],
        tooltip: filledTooltip
    },
    Humidity: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Vlaga %'
            },
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series : [
            {
                name: 'Vlaga',
                color: 'green',
                data: []
            }
        ],
        tooltip: filledTooltip
    },
    Pressure: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Zračni tlak hPa'
            },
        },
        series : [
            {
                name: 'Zračni tlak',
                color: 'purple',
                data: []
            }
        ],
        tooltip: filledTooltip
    },
    Rain: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Padavine mm'
            },
        },
        series: [
            {
                type: 'column',
                name: 'Padavine',
                color: 'blue',
                data: []
            }
        ],
        tooltip: filledTooltip
    },
    Wind: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Veter m/s'
            },
        },
        series: [
            {
                name: 'Sunki vetra',
                color: 'black',
                data: []
            },
            {
                name: 'Hitrost vetra',
                color: 'gray',
                data: []
            }
        ],
        tooltip: filledTooltip
    },
    Dewpoint: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            labels: {
                formatter: {}
            },
            tickInterval: 0
        },
        yAxis: {
            title: {
                text: 'Rosišče °C'
            },
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series: [
            {
                name: 'Rosišče',
                color: 'orange',
                data: []
            }
        ],
        tooltip: filledTooltip
    },
    UV: {
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: 'Sončno obsevanje W/m2'
            },
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series: [
            {
                name: 'Sončno obsevanje',
                color: 'tomato',
                data: []
            }
        ],
        tooltip: filledTooltip
    }
}