import moment from 'moment';

function CalculateYearly(response, StationIDs) {
    var edited = [];
    edited = response.data.data;
    for (let i = 0; i < edited.length; i++) {
        const element = edited[i];
        element.DateTime = moment(element.DateTime).format('MM');
    }
    const groupBy = (arr) => arr.reduce((acc, ele)=>( (acc[ele.DateTime.split(' ')[0]] = acc[ele.DateTime.split(' ')[0]] || []).push(ele), acc),{})
    const sum =  (arr) => Math.max(...arr.map(res=>res[`${StationIDs}`]).filter(Number));
    
    return Object.entries(groupBy(edited)).map(([key, val])=> ({date:moment(key), sum:sum(val)}))
}

export default CalculateYearly;