export const AxiosUrl = {
    LoskiPotok: {
        Daily: {
            Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetHribRetjeDaily`
        }
    },
    Sodrazica: {
        Izver_Spodaj : {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Wunderground/GetIzverSpodajDaily`
            }
        },
        Izver_Zgoraj : {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Wunderground/GetIzverZgorajDaily`
            }
        }
    },
    Ribnica: {
        Daily: {
            Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRibnicaPrigoricaSvAnaDaily`,
        },
        DailySlemena: {
            Url: `http://data.vreme-podnebje.si:5000/api/Wunderground/GetSlemenaDaily`,
        },
    },
    LoskiPotokDetails: {
        Hrib: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetHribDaily`
            },
            Weekly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetHribWeekly`
            },
            Monthly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetHribMonthly`
            },
        },
        Retje: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRetjeDaily`
            },
            Weekly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRetjeWeekly`
            },
            Monthly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRetjeMonthly`
            },
        }
    },
    RibnicaDetails: {
        Prigorica: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetPrigoricaDaily`
            },
            Weekly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetPrigoricaWeekly`
            },
            Monthly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetPrigoricaMonthly`
            },
        },
        Ribnica: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRibnicaDaily`
            },
            Weekly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRibnicaWeekly`
            },
            Monthly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetRibnicaMonthly`
            },
        },
        SvAna: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetSvAnaDaily`
            },
            Weekly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetSvAnaWeekly`
            },
            Monthly: {
                Url: `http://data.vreme-podnebje.si:5000/api/Pileus/GetSvAnaMonthly`
            },
        },
        Slemena: {
            Daily: {
                Url: `http://data.vreme-podnebje.si:5000/api/Wunderground/GetSlemenaDaily`
            }
        }
    }
}

export default AxiosUrl;