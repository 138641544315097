export var TableSettings = {
    LoskiPotok: [
        {
            id: 'LYk4p9r8RA',
            name: 'Hrib',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        },
        {
            id: 'NxEkuvG2VT',
            name: 'Retje',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        }
    ],
    Sodrazica: [
        {
            id: 'Is6cqykhG1',
            name: 'Izver spodaj',
            temp: null,
            hum: null,
            date: null,
            wind: null,
            dir: null,
            titleDate: null,
            isLate: false
        },
        {
            id: '10s1ylZBdq0',
            name: 'Izver na vrhu',
            temp: null,
            hum: null,
            date: null,
            wind: null,
            dir: null,
            titleDate: null,
            isLate: false
        },
    ],
    Ribnica: [
        {
            id: 'Cy3kas34kV',
            name: 'Ribnica',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        },
        {
            id: 'KMn2x1KsWgQ',
            name: 'Prigorica',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        },
        {
            id: 'HkumAw2XoQs',
            name: 'Gašpinovo',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        },
        {
            id: 'Rds23w5XoyD',
            name: 'Sv. Ana',
            temp: null,
            hum: null,
            date: null,
            isLate: false
        }
    ]
}

export default TableSettings;