import StationSettings from '../../Data/Station/StationSettings';
import moment from 'moment';
import Parameter from './Parameter';
import router from '../../router/index'
import Calculate from '../Stats/Calculate/Calculate';
import CalculateRain from '../Stats/Calculate/CalculateRain';
import CalculateYearly from '../Stats/Calculate/Year/CalculateYearly';
import CalculateRainYearly from '../Stats/Calculate/Year/CalculateRainYearly';
import Clear from '../Stats/Clear/Clear';
import { SlemenaGraphs, SlemenaValidation } from './Slemena/Slemena';
import $ from 'jquery';
import { SodrazicaGraphs } from './Sodrazica/SodrazicaGraph';
import { Ribnica } from '../../objects/Ribnica';
import { LoskiPotok } from '../../objects/LoskiPotok';
import { Sodrazica } from '../../objects/Sodrazica';
import { Gaspinovo } from '../../objects/Gaspinovo';

class Station {
    constructor(ChartOptions, TableSettings) {
        this.ChartOptions = ChartOptions;
        this.TableSettings = TableSettings;
    }

    GetLabelFormatter() {
        let tool = {
            formatter: function() {
                var s = null;
                if(router.currentRoute._value.params.stationRange === 'daily') {
                    s = '<b>'+ moment(this.x).format('DD. MM YYYY HH:mm') +'</b>';
                }
                else {
                    s = '<b>'+ moment(this.x).format('DD. MM YYYY') +'</b>';
                }
        
                $.each(this.points, function(i, point) {
                        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
                });
        
                return s;
            },
            shared: true
        }
        return tool;
    }

    GetDailyFormatter() {
        let tool = {
            formatter: function() {
                var s = '<b>'+ moment(this.x).format('DD. MM. YYYY HH:mm') +'</b>';
        
                $.each(this.points, function(i, point) {
                        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
                });
        
                return s;
            },
            shared: true
        }
        return tool;
    }

    ChangeTimezone(response) {
        if (router.currentRoute.value.params.stationName != 'sodrazica' && router.currentRoute.value.params.stationId !== 'HkumAw2XoQs' && router.currentRoute.value.params.stationId !== 'Is6cqykhG1' && router.currentRoute.value.params.stationId !== '10s1ylZBdq0') {
            response.data.original.data.forEach(element => {
                element.date = moment(element.date).format('YYYY-MM-DD HH:mm:ss');
            });
        }
    }

    GetData(response, sodrazicaResponse, loskiResponse) {
        var statsData = null;
        console.log(response);
        console.log(sodrazicaResponse);
        console.log(loskiResponse);

        //this.ChangeTimezone(response);

        StationSettings.ChartSettings.Temperature.yAxis = this.ChartOptions.yAxis;
        StationSettings.ChartSettings.Temperature.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Temperature.yAxis.title.text = 'Temperatura °C';

        StationSettings.ChartSettings.Temperature.xAxis.labels.formatter = function () {
            return moment(String(this.value)).format('HH:00');
        }

        StationSettings.ChartSettings.Temperature.tooltip = this.GetDailyFormatter();

        if (router.currentRoute._value.params.stationName === 'loski-potok' || router.currentRoute.value.fullPath === '/stations') {
            var loskiPotok = new LoskiPotok();

            var originalArray = response.data.original.data;
            statsData = response.data.stats;

            loskiPotok.PrepareOriginal(originalArray);
            loskiPotok.PrepareStats(statsData);

            loskiPotok.AddChartData(StationSettings);
            loskiPotok.AddChartProperties(StationSettings);

            loskiPotok.AddTableData(StationSettings);

            loskiPotok.AddRecordData(StationSettings);

            loskiPotok.ValidateRecordData(StationSettings);
            
            loskiPotok.ValidateNullableData(loskiPotok);
        }
        
        if (router.currentRoute._value.params.stationName === 'sodrazica' || router.currentRoute.value.fullPath === '/stations') {
            var izverSpodaj = new Sodrazica();
            var izverZgoraj = new Sodrazica();

            var detailsArrayIzverZgoraj = response.data.details;
            var generalDataIzverZgoraj = response.data.general
            var statsDataIzverZgoraj = response.data.stats
            var unitsDataIzverZgoraj = response.data.units

            var detailsArrayIzverSpodaj = sodrazicaResponse.data.details;
            var generalDataIzverSpodaj = sodrazicaResponse.data.general
            var statsDataIzverSpodaj = sodrazicaResponse.data.stats
            var unitsDataIzverSpodaj = sodrazicaResponse.data.units

            izverSpodaj.PrepareDetails(detailsArrayIzverSpodaj);
            izverSpodaj.PrepareGeneral(generalDataIzverSpodaj);
            izverSpodaj.PrepareStats(statsDataIzverSpodaj);
            izverSpodaj.PrepareUnits(unitsDataIzverSpodaj);

            izverZgoraj.PrepareDetails(detailsArrayIzverZgoraj);
            izverZgoraj.PrepareGeneral(generalDataIzverZgoraj);
            izverZgoraj.PrepareStats(statsDataIzverZgoraj);
            izverZgoraj.PrepareUnits(unitsDataIzverZgoraj);

            izverSpodaj.RemovedInvalidChartSeries(StationSettings);

            var sodrazica = izverSpodaj.AddChartData(izverSpodaj, izverZgoraj, StationSettings);

            izverSpodaj.AddChartProperties(StationSettings);

            izverSpodaj.AddTableData(StationSettings, sodrazica, izverZgoraj, izverSpodaj);

            izverSpodaj.ValidateNullableData(StationSettings, izverZgoraj, izverSpodaj);
        }

        if (router.currentRoute._value.params.stationName === 'ribnica' || router.currentRoute.value.fullPath === '/stations') {
            var ribnica = new Ribnica();
            var gaspinovo = new Gaspinovo();

            originalArray = response.data.original.data;
            statsData = response.data.stats;

            var detailsArrayGaspinovo = sodrazicaResponse.data.details;
            var generalDataGaspinovo = sodrazicaResponse.data.general
            var statsDataGaspinovo = sodrazicaResponse.data.stats
            var unitsDataGaspinovo = sodrazicaResponse.data.units

            gaspinovo.PrepareDetails(detailsArrayGaspinovo);
            gaspinovo.PrepareGeneral(generalDataGaspinovo);
            gaspinovo.PrepareStats(statsDataGaspinovo);
            gaspinovo.PrepareUnits(unitsDataGaspinovo);

            var slemena = gaspinovo.AddChartData(StationSettings);

            ribnica.RemovedInvalidChartSeries(StationSettings);

            ribnica.PrepareOriginal(originalArray);
            ribnica.PrepareStats(statsData);

            var svAna = ribnica.AddChartData(StationSettings);
            ribnica.AddChartProperties(StationSettings);

            ribnica.AddTableData(StationSettings);

            ribnica.ValidateRecordData(StationSettings);
            
            if (StationSettings.ChartSettings.Temperature.series.length < 4) {
                StationSettings.ChartSettings.Temperature.series.push({ name: 'Gašpinovo', data: slemena.temp, color: '#0099FF' });
                StationSettings.ChartSettings.Temperature.series.push({ name: 'Sv. Ana', data: svAna , color: 'blue' });
            }
            
            gaspinovo.AddChartData(StationSettings);
            gaspinovo.AddTableData(StationSettings);
            gaspinovo.ValidateNullableData(StationSettings);

            ribnica.AddSvAnaTableData(StationSettings);

            ribnica.AddRecordData(StationSettings, gaspinovo);

            ribnica.ValidateNullableData(ribnica);
        }
        else if (router.currentRoute.value.fullPath === '/stations') {
            var ribnicaCurrent = new Ribnica();
            var gaspinovoCurrent = new Gaspinovo();

            statsData = response.data.stats;
            statsDataGaspinovo = sodrazicaResponse.data.stats
            ribnicaCurrent.PrepareStats(statsData);
            gaspinovoCurrent.PrepareStats(statsDataGaspinovo);

            var hribT = response.data.data.map(x => x[`${process.env.VUE_APP_HRIB_TEMPERATURE_ID}`]).filter(Number);
            var retjeT = response.data.data.map(x => Math.round(x[`${process.env.VUE_APP_RETJE_TEMPERATURE_ID}`] * 10) / 10).filter(Number);
            var hribH = response.data.data.map(x => x[`${process.env.VUE_APP_HRIB_HUMIDITY_ID}`]).filter(Number);
            var retjeH = response.data.data.map(x => Math.round(x[`${process.env.VUE_APP_RETJE_HUMIDITY_ID}`]));

            var hribD = response.data.data.map(x => ({DateTime: x.DateTime, Temp: x[`${process.env.VUE_APP_HRIB_TEMPERATURE_ID}`]}));
            hribD = hribD.filter(x => x.Temp == hribT[hribT.length - 1]);
            hribD = hribD.map(x => x.DateTime);

            StationSettings.TableSettings.LoskiPotok[0].temp = hribT[hribT.filter(Number).length - 1];
            StationSettings.TableSettings.LoskiPotok[0].hum = hribH[hribH.filter(Number).length - 1];
            StationSettings.TableSettings.LoskiPotok[0].date = moment(hribD[hribD.length - 1]).format('DD. MM. Y HH:mm');

            var retjeD = response.data.data.map(x => ({DateTime: x.DateTime, Temp: Math.round(x[`${process.env.VUE_APP_RETJE_TEMPERATURE_ID}`] * 10) / 10}));
            retjeD = retjeD.filter(x => isNaN(x.Temp) === false)
            retjeD = retjeD.filter(x => x.Temp == retjeT[retjeT.length - 1]);
            retjeD = retjeD.map(x => x.DateTime);

            StationSettings.TableSettings.LoskiPotok[1].temp = retjeT[retjeT.filter(Number).length -  1];
            StationSettings.TableSettings.LoskiPotok[1].hum = retjeH[retjeH.filter(Number).length - 1];
            StationSettings.TableSettings.LoskiPotok[1].date = moment(retjeD[retjeD.length - 1]).format('DD. MM. Y HH:mm');
        }
        return StationSettings;
    }

    GetDetailsData(response, StationIDs) {
        this.ChangeTimezone(response);

        if(SlemenaValidation(router.currentRoute._value.params) == true) {
            SlemenaGraphs(response, StationSettings, this.ChartOptions, this.TableSettings)
        }
        else if (router.currentRoute.value.params.stationId == 'Is6cqykhG1') {
            SodrazicaGraphs(response, StationSettings, this.ChartOptions, this.TableSettings);
        }
        else if (router.currentRoute.value.params.stationId == '10s1ylZBdq0') {
            SodrazicaGraphs(response, StationSettings, this.ChartOptions, this.TableSettings);
        }
        else {
            var parameter = new Parameter();

            Clear(StationSettings.ChartSettings.Temp)
            Clear(StationSettings.ChartSettings.Humidity)
            Clear(StationSettings.ChartSettings.Pressure)
            Clear(StationSettings.ChartSettings.Rain)
            Clear(StationSettings.ChartSettings.Wind)
            Clear(StationSettings.ChartSettings.Dewpoint)
            StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
                return moment(String(this.value)).format('HH:00');  
            }
    
            StationSettings.ChartSettings.Temp.series.push({ data: [], name: 'Temperatura', color: 'red' });
            StationSettings.ChartSettings.Humidity.series.push({ data: [], name: 'Vlaga', color: 'green' });
            StationSettings.ChartSettings.Pressure.series.push({ data: [], name: 'Zračni tlak', color: 'black' });
            StationSettings.ChartSettings.Rain.series.push({ data: [], name: 'Padavine', color: 'blue' });
            StationSettings.ChartSettings.Wind.series.push({ data: [], name: 'Sunki vetra', color: 'black' }, { data: [], name: 'Hitrost vetra', color: 'gray' });
            StationSettings.ChartSettings.Dewpoint.series.push({ data: [], name: 'Rosišče', color: 'orange' });
            StationSettings.ChartSettings.UV.series.push({ data: [], name: 'Sončno obsevanje', color: 'tomato'})
    
            StationSettings.ChartSettings.Temp.series.length = 1;
            StationSettings.ChartSettings.Humidity.series.length = 1;
            StationSettings.ChartSettings.Pressure.series.length = 1;
            StationSettings.ChartSettings.Rain.series.length = 1;
            StationSettings.ChartSettings.Wind.series.length = 2;
            StationSettings.ChartSettings.Dewpoint.series.length = 1;
            StationSettings.ChartSettings.UV.series.length = 1;
            
            StationSettings.ChartSettings.Temp.yAxis.tickPositioner = this.ChartOptions.yAxis.tickPositioner;
            StationSettings.ChartSettings.Temp.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.Humidity.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.Pressure.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.Rain.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.Wind.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.Dewpoint.xAxis = this.ChartOptions.xAxis;
            StationSettings.ChartSettings.UV.xAxis = this.ChartOptions.xAxis;

            StationSettings.ChartSettings.Temp.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.Humidity.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.Pressure.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.Rain.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.Wind.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.Dewpoint.tooltip = this.GetDailyFormatter();
            StationSettings.ChartSettings.UV.tooltip = this.GetDailyFormatter();
    
            if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ' || router.currentRoute._value.params.stationId === 'Rds23w5XoyD') {
                response.data.data.forEach(element => {
                    element[`${StationIDs.hum}`] = Math.round( (element[`${StationIDs.hum}`])* 10 ) / 10; 
                    element[`${StationIDs.windGust}`] = Math.round( ((element[`${StationIDs.windGust}`]) / 3.6) * 10 ) / 10; 
                    element[`${StationIDs.windSpeed}`] = Math.round( ((element[`${StationIDs.windSpeed}`]) / 3.6) * 10 ) / 10; 
                });
            }

            StationSettings.ChartSettings.Temp.series[0].data = response.data.data.map((x) => x[`${StationIDs.temp}`] === undefined || x[`${StationIDs.temp}`] === null ? null : Math.round(x[`${StationIDs.temp}`] * 10) / 10);
            StationSettings.ChartSettings.Temp.xAxis.categories = response.data.data.map((x) => moment(x.DateTime));
    
            StationSettings.LastRefresh = moment(StationSettings.ChartSettings.Temp.xAxis.categories[StationSettings.ChartSettings.Temp.xAxis.categories.length - 1]).format('DD. MM. Y HH:mm');
    
            StationSettings.ChartSettings.Humidity.series[0].data = response.data.data.map((x) => x[`${StationIDs.hum}`] === undefined || x[`${StationIDs.hum}`] === null || x[`${StationIDs.hum}`] == 0 ? null : x[`${StationIDs.hum}`]);
            StationSettings.ChartSettings.Humidity.xAxis.categories = response.data.data.map((x) => moment(x.DateTime));
    
            StationSettings.ChartSettings.Pressure.series[0].data = response.data.data.map((x) => x[`${StationIDs.pressure}`]);
            StationSettings.ChartSettings.Pressure.xAxis.categories = response.data.data.map((x) => moment(x.DateTime));
    
            StationSettings.ChartSettings.Rain.series[0].data = response.data.data.map((x) => x[`${StationIDs.rainDay}`]);
            StationSettings.ChartSettings.Rain.xAxis.categories = response.data.data.map((x) => moment(x.DateTime));
    
            StationSettings.ChartSettings.Wind.series[0].data = response.data.data.map((x) => router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ' ? Math.round(((x[`${StationIDs.windGust}`]) / 3.6) * 10) / 10 : x[`${StationIDs.windGust}`]);
            StationSettings.ChartSettings.Wind.series[1].data = response.data.data.map((x) => router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ' ? Math.round(((x[`${StationIDs.windSpeed}`]) / 3.6) * 10) / 10 : x[`${StationIDs.windSpeed}`]);
    
            StationSettings.ChartSettings.Dewpoint.series[0].data = response.data.data.map((x) => x[`${StationIDs.dewpoint}`] === undefined || x[`${StationIDs.dewpoint}`] === null ? null : Math.round(x[`${StationIDs.dewpoint}`] * 10) / 10);

            StationSettings.ChartSettings.UV.series[0].data = response.data.data.map((x) => x[`${StationIDs.uv}`])
            StationSettings.ChartSettings.UV.xAxis.categories = response.data.data.map((x) => moment(x.DateTime));
    
            StationSettings.DetailsSettings = this.TableSettings;

            if (router.currentRoute._value.params.stationId === 'NxEkuvG2VT') {
                parameter.GetStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
                parameter.GetStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
                parameter.GetStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            }
            else if (router.currentRoute._value.params.stationId === 'Rds23w5XoyD') {
                parameter.GetStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
                parameter.GetStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
                parameter.GetStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
                parameter.GetStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            }
            else if (router.currentRoute._value.params.stationId === 'Cy3kas34kV') {
                parameter.GetStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
                parameter.GetStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
                parameter.GetRainStats(response, StationIDs.rain, StationIDs.rainDay, StationSettings.DetailsSettings.rain);
                parameter.GetWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
                parameter.GetStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            }
            else if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ') {
                parameter.GetStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
                parameter.GetStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
                parameter.GetStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
                parameter.GetRainStats(response, StationIDs.rain, StationIDs.rainDay, StationSettings.DetailsSettings.rain);
                parameter.GetStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
                parameter.GetWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed)
                parameter.GetStats(response, StationIDs.uv, StationSettings.DetailsSettings.uv)
            }
            else {
                parameter.GetStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
                parameter.GetStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
                parameter.GetStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
                parameter.GetRainStats(response, StationIDs.rain, StationIDs.rainDay, StationSettings.DetailsSettings.rain);
                parameter.GetWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
                parameter.GetStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            }
        }
        return StationSettings;
    }

    GetDetailsMonthlyData(response, StationIDs) {
        this.ChangeTimezone(response);
        if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ') {
            response.data.data.forEach(element => {
                element[`${StationIDs.windGust}`] = Math.round( ((element[`${StationIDs.windGust}`]) / 3.6) * 10 ) / 10; 
                element[`${StationIDs.windSpeed}`] = Math.round( ((element[`${StationIDs.windSpeed}`]) / 3.6) * 10 ) / 10; 
            });
        }

        var parameter = new Parameter();
        StationSettings.ChartSettings.Temp.yAxis.tickPositioner = this.ChartOptions.yAxis.tickPositioner;
        StationSettings.ChartSettings.Temp.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Humidity.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Pressure.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Rain.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Wind.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Dewpoint.xAxis = this.ChartOptions.xAxis;

        const temperature = Calculate(response, StationIDs.temp);
        StationSettings.ChartSettings.Temp.xAxis.categories = [];
        StationSettings.ChartSettings.Temp.series = [{ data: [] }];
        StationSettings.ChartSettings.Temp.series.push({ name: 'maks', data: temperature.map(x => x.max), color: 'red' })
        StationSettings.ChartSettings.Temp.series.push({ name: 'min', data: temperature.map(x => x.min == 0 ? null : x.min), color: 'blue' })
        StationSettings.ChartSettings.Temp.series.push({ name: 'avg', data: temperature.map(x => x.avg), color: 'orange' })
        StationSettings.ChartSettings.Temp.series.shift();

        StationSettings.LastRefresh = moment(StationSettings.ChartSettings.Temp.xAxis.categories[StationSettings.ChartSettings.Temp.xAxis.categories.length - 1]).format('DD. MM. Y HH:mm');

        const humidity = Calculate(response, StationIDs.hum);
        StationSettings.ChartSettings.Humidity.xAxis.categories = [];
        StationSettings.ChartSettings.Humidity.series = [{ data: [] }];
        StationSettings.ChartSettings.Humidity.series.push({ name: 'maks', data: humidity.map(x => x.max == 0 ? null : x.max), color: '#472DAE' })
        StationSettings.ChartSettings.Humidity.series.push({ name: 'min', data: humidity.map(x => x.min == 0 ? null : x.min), color: '#3344FF' })
        StationSettings.ChartSettings.Humidity.series.push({ name: 'avg', data: humidity.map(x => x.avg == 0 ? null : x.avg), color: 'aqua' })
        StationSettings.ChartSettings.Humidity.xAxis.categories = humidity.map(x => x.date);
        StationSettings.ChartSettings.Humidity.series.shift();

        const pressure = Calculate(response, StationIDs.pressure)
        StationSettings.ChartSettings.Pressure.xAxis.categories = [];
        StationSettings.ChartSettings.Pressure.series = [{ data: [] }];
        StationSettings.ChartSettings.Pressure.series.push({ name: 'maks', data: pressure.map(x => x.max == 0 ? null : x.max), color: 'violet' });
        StationSettings.ChartSettings.Pressure.series.push({ name: 'min', data: pressure.map(x => x.min == 0 ? null : x.min), color: '#6757A6' });
        StationSettings.ChartSettings.Pressure.series.push({ name: 'avg', data: pressure.map(x => x.avg == 0 ? null: x.avg), color: '#180D43' });
        StationSettings.ChartSettings.Pressure.xAxis.categories = pressure.map(x => x.date);
        StationSettings.ChartSettings.Pressure.series.shift();

        const rain = CalculateRain(response, StationIDs.rainMonth);
        StationSettings.ChartSettings.Rain.xAxis.categories = [];
        StationSettings.ChartSettings.Rain.series = [{ data: [] }];
        StationSettings.ChartSettings.Rain.series.push({ name: 'maks', data: rain.map(x => x.sum), color: 'blue' })
        StationSettings.ChartSettings.Rain.xAxis.categories = rain.map(x => x.date);
        StationSettings.ChartSettings.Rain.series.shift();

        const wind = Calculate(response, StationIDs.windGust)
        StationSettings.ChartSettings.Wind.xAxis.categories = [];
        StationSettings.ChartSettings.Wind.series = [{ data: [] }];
        StationSettings.ChartSettings.Wind.series.push({ name: 'maks', data: wind.map(x => x.max), color: 'black' });
        StationSettings.ChartSettings.Wind.xAxis.categories = wind.map(x => x.date);
        StationSettings.ChartSettings.Wind.series.shift();

        const dewpoint = Calculate(response, StationIDs.dewpoint);
        StationSettings.ChartSettings.Dewpoint.xAxis.categories = [];
        StationSettings.ChartSettings.Dewpoint.series = [{ data: [] }];
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'maks', data: dewpoint.map(x => x.max), color: 'orange' });
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'min', data: dewpoint.map(x => x.min).filter(Number), color: 'brown' });
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'avg', data: dewpoint.map(x => x.avg), color: 'yellow' });
        StationSettings.ChartSettings.Dewpoint.series.shift();

        const uv = Calculate(response, StationIDs.uv);
        StationSettings.ChartSettings.UV.xAxis.categories = [];
        StationSettings.ChartSettings.UV.series = [{ data: [] }];
        StationSettings.ChartSettings.UV.series.push({ name: 'maks', data: uv.map(x => x.max), color: 'orange' });
        //StationSettings.ChartSettings.UV.series.push({ name: 'min', data: uv.map(x => x.min).filter(Number), color: 'red' });
        StationSettings.ChartSettings.UV.series.push({ name: 'avg', data: uv.map(x => x.avg), color: 'yellow' });
        StationSettings.ChartSettings.UV.series.shift();
        // TODO this applies to every graph
        StationSettings.ChartSettings.Dewpoint.xAxis.categories = wind.map(x => moment(x.date));
        StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
            return moment(String(this.value)).format('DD. MM.');
        }
        StationSettings.ChartSettings.Dewpoint.xAxis.tickInterval = 3;

        StationSettings.DetailsSettings = this.TableSettings;

        if (router.currentRoute._value.params.stationId === 'NxEkuvG2VT') {
            parameter.GetMonthlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetMonthlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetMonthlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }
        else if (router.currentRoute._value.params.stationId === 'Rds23w5XoyD') {
            parameter.GetMonthlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetMonthlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetMonthlyStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
            parameter.GetMonthlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }
        else if (router.currentRoute._value.params.stationId === 'Cy3kas34kV') {
            parameter.GetMonthlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetMonthlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetDetailsRainStats(response, StationIDs.rain, StationIDs.rainMonth, StationSettings.DetailsSettings.rain);
            parameter.GetMonthlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetMonthlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }
        else if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ') {
            parameter.GetMonthlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetMonthlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetMonthlyStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
            parameter.GetDetailsRainStats(response, StationIDs.rain, StationIDs.rainMonth, StationSettings.DetailsSettings.rain);
            parameter.GetMonthlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            parameter.GetMonthlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetMonthlyStats(response, StationIDs.uv, StationSettings.DetailsSettings.uv);
        }
        else {
            parameter.GetMonthlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetMonthlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetMonthlyStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
            parameter.GetDetailsRainStats(response, StationIDs.rain, StationIDs.rainMonth, StationSettings.DetailsSettings.rain);
            parameter.GetMonthlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetMonthlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }

        return StationSettings;
    }

    GetDetailsYearlyData(response, StationIDs, rainResponse) {
        this.ChangeTimezone(response);
        if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ') {
            response.data.data.forEach(element => {
                element[`${StationIDs.windGust}`] = Math.round( ((element[`${StationIDs.windGust}`]) / 3.6) * 10 ) / 10; 
                element[`${StationIDs.windSpeed}`] = Math.round( ((element[`${StationIDs.windSpeed}`]) / 3.6) * 10 ) / 10; 
            });
        }
        var parameter = new Parameter();
        StationSettings.ChartSettings.Temp.yAxis.tickPositioner = this.ChartOptions.yAxis.tickPositioner;
        StationSettings.ChartSettings.Temp.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Humidity.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Pressure.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Rain.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Wind.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Dewpoint.xAxis = this.ChartOptions.xAxis;

        const temperature = CalculateYearly(response, StationIDs.temp);
        StationSettings.ChartSettings.Temp.xAxis.categories = [];
        StationSettings.ChartSettings.Temp.series = [{ data: [] }];
        StationSettings.ChartSettings.Temp.series.push({ name: 'maks', data: temperature.map(x => x.max == 0 ? null : x.max), color: 'red' })
        StationSettings.ChartSettings.Temp.series.push({ name: 'min', data: temperature.map(x => x.min == 0 ? null : x.min).filter(Number), color: 'blue' })
        StationSettings.ChartSettings.Temp.series.push({ name: 'avg', data: temperature.map(x => x.avg == 0 ? null : x.avg), color: 'orange' })
        StationSettings.ChartSettings.Temp.series.shift();

        StationSettings.LastRefresh = moment(StationSettings.ChartSettings.Temp.xAxis.categories[StationSettings.ChartSettings.Temp.xAxis.categories.length - 1]).format('DD. MM. Y HH:mm');

        const humidity = CalculateYearly(response, StationIDs.hum);
        StationSettings.ChartSettings.Humidity.xAxis.categories = [];
        StationSettings.ChartSettings.Humidity.series = [{ data: [] }];

        StationSettings.ChartSettings.Humidity.series.push({ name: 'maks', data: humidity.map(x => x.max == 0 ? null : x.max), color: '#472DAE' })
        StationSettings.ChartSettings.Humidity.series.push({ name: 'min', data: humidity.map(x => x.min == 0 ? null : x.min), color: '#3344FF' })
        StationSettings.ChartSettings.Humidity.series.push({ name: 'avg', data: humidity.map(x => x.avg == 0 ? null : x.avg), color: 'aqua' })
        StationSettings.ChartSettings.Humidity.xAxis.categories = humidity.map(x => x.date);
        StationSettings.ChartSettings.Humidity.series.shift();

        const pressure = CalculateYearly(response, StationIDs.pressure)
        StationSettings.ChartSettings.Pressure.xAxis.categories = [];
        StationSettings.ChartSettings.Pressure.series = [{ data: [] }];
        StationSettings.ChartSettings.Pressure.series.push({ name: 'maks', data: pressure.map(x => x.max == 0 ? null : x.max), color: 'violet' });
        StationSettings.ChartSettings.Pressure.series.push({ name: 'min', data: pressure.map(x => x.min == 0 ? null : x.min), color: '#6757A6' });
        StationSettings.ChartSettings.Pressure.series.push({ name: 'avg', data: pressure.map(x => x.avg == 0 ? null : x.avg), color: '#180D43' });
        StationSettings.ChartSettings.Pressure.xAxis.categories = pressure.map(x => x.date);
        StationSettings.ChartSettings.Pressure.series.shift();

        if (router.currentRoute._value.params.stationId !== 'NxEkuvG2VT' && router.currentRoute._value.params.stationId !== 'Cy3kas34kV' && router.currentRoute._value.params.stationId !== 'KMn2x1KsWgQ') {
            const rain = CalculateRainYearly(rainResponse, StationIDs.rainYear);
            StationSettings.ChartSettings.Rain.xAxis.categories = [];
            StationSettings.ChartSettings.Rain.series = [{ data: [] }];
            StationSettings.ChartSettings.Rain.series.push({ name: 'maks', data: rain.map(x => x.sum == 0 ? null : x.sum), color: 'blue' })
            StationSettings.ChartSettings.Rain.xAxis.categories = rain.map(x => x.date);
            StationSettings.ChartSettings.Rain.series.shift();
        }


        const wind = CalculateYearly(response, StationIDs.windGust)
        StationSettings.ChartSettings.Wind.xAxis.categories = [];
        StationSettings.ChartSettings.Wind.series = [{ data: [] }];
        StationSettings.ChartSettings.Wind.series.push({ name: 'maks', data: wind.map(x => x.max == 0 ? null : x.max), color: 'black' });
        StationSettings.ChartSettings.Wind.xAxis.categories = wind.map(x => x.date);
        StationSettings.ChartSettings.Wind.series.shift();

        const dewpoint = CalculateYearly(response, StationIDs.dewpoint);
        StationSettings.ChartSettings.Dewpoint.xAxis.categories = [];
        StationSettings.ChartSettings.Dewpoint.series = [{ data: [] }];
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'maks', data: dewpoint.map(x => x.max == 0 ? null : x.max), color: 'orange' });
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'min', data: dewpoint.map(x => x.min == 0 ? null : x.min).filter(Number), color: 'brown' });
        StationSettings.ChartSettings.Dewpoint.series.push({ name: 'avg', data: dewpoint.map(x => x.avg == 0 ? null : x.avg), color: 'yellow' });
        StationSettings.ChartSettings.Dewpoint.series.shift();
        // TODO this applies to every graph
        StationSettings.ChartSettings.Dewpoint.xAxis.categories = wind.map(x => moment(x.date));
        StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
            return moment(String(this.value)).format('DD. MM.');
        }
        StationSettings.ChartSettings.Dewpoint.xAxis.tickInterval = 3;

        StationSettings.DetailsSettings = this.TableSettings;

        if (router.currentRoute._value.params.stationId === 'NxEkuvG2VT') {
            parameter.GetYearlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetYearlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetYearlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }
        else if (router.currentRoute._value.params.stationId === 'Cy3kas34kV') {
            parameter.GetYearlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetYearlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetYearlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetYearlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }
        else if (router.currentRoute._value.params.stationId === 'KMn2x1KsWgQ') {
            parameter.GetYearlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetYearlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetYearlyStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
            parameter.GetDetailsRainStats(response, StationIDs.rain, StationIDs.rainDay, StationSettings.DetailsSettings.rain);
            parameter.GetYearlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetYearlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
            parameter.GetYearlyStats(response, StationIDs.uv, StationSettings.DetailsSettings.uv);
        }
        else {
            parameter.GetYearlyStats(response, StationIDs.temp, StationSettings.DetailsSettings.temperature);
            parameter.GetYearlyStats(response, StationIDs.hum, StationSettings.DetailsSettings.humidity);
            parameter.GetYearlyStats(response, StationIDs.pressure, StationSettings.DetailsSettings.pressure);
            parameter.GetDetailsRainStats(rainResponse, StationIDs.rain, StationIDs.rainYear, StationSettings.DetailsSettings.rain);
            parameter.GetYearlyWindStats(response, StationIDs.windGust, StationIDs.windSpeed, StationSettings.DetailsSettings.windSpeed);
            parameter.GetYearlyStats(response, StationIDs.dewpoint, StationSettings.DetailsSettings.dewpoint)
        }

        return StationSettings;
    }
}

export default Station;