import mathAverage from 'math-avg';
import moment from 'moment';

function CalculateYearly(response, StationIDs) {
    var edited = response.data.data;

    for (let i = 0; i < edited.length; i++) {
        const element = edited[i];
        element.DateTime = moment(element.DateTime).format('Y-MM');
    }
    const groupBy = (arr) => arr.reduce((acc, ele)=>( (acc[ele.DateTime] = acc[ele.DateTime] || []).push(ele), acc),{})
    const max  =  (arr) => Math.round(Math.max(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    const min  =  (arr) => Math.round(Math.min(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    const avg = (arr) => Math.round(mathAverage(...arr.map(res=>res[`${StationIDs}`])) * 10) / 10;
    
    return Object.entries(groupBy(edited)).map(([key, val])=> ({date:moment(key), min:min(val), max: max(val), avg: avg(val)}))
}

export default CalculateYearly;