import moment from 'moment';
import { AddDailyTimeSeries } from '../Utils/Stats/Time/AddTimeSeries';

class LoskiPotok {
    constructor() {
        this.original = [];
        this.stats = {};
    }

    PrepareOriginal(originalArray) {
        originalArray.forEach(x => {
            var original = new Original(
                x.hribTemperature,
                x.hribHumidity,
                x.retjeTemperature,
                x.retjeHumidity,
                x.date
            )
            this.original.push(original);
        });
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.hribCurrentTemperature,
            statsData.hribCurrentHumidity,
            statsData.hribMaxTemperature,
            statsData.hribMinTemperature,
            statsData.lastUpdatedHrib,
            statsData.retjeCurrentTemperature,
            statsData.retjeCurrentHumidity,
            statsData.retjeMaxTemperature,
            statsData.retjeMinTemperature,
            statsData.lastUpdatedRetje
        )
        this.stats = stats;
    }

    AddChartData(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].data = this.original.map(x => x.hribTemperature);
        StationSettings.ChartSettings.Temperature.series[1].data = this.original.map(x => x.retjeTemperature);

        StationSettings.ChartSettings.Temperature.xAxis.categories = AddDailyTimeSeries();
    }

    AddChartProperties(StationSettings) {
        StationSettings.ChartSettings.Temperature.chart.plotBackgroundColor = '#f7f5ed';
        StationSettings.ChartSettings.Temperature.series[0].color = 'brown';
        StationSettings.ChartSettings.Temperature.series[1].color = '#E6E61A';
    }

    AddTableData(StationSettings) {
        StationSettings.TableSettings.LoskiPotok[0].temp = this.stats.hribCurrentTemperature;
        StationSettings.TableSettings.LoskiPotok[0].hum = this.stats.hribCurrentHumidity;
        StationSettings.TableSettings.LoskiPotok[0].date = moment(this.stats.lastUpdatedHrib).format('DD. MM. Y HH:mm');

        StationSettings.TableSettings.LoskiPotok[1].temp = this.stats.retjeCurrentTemperature;
        StationSettings.TableSettings.LoskiPotok[1].hum = this.stats.retjeCurrentHumidity
        StationSettings.TableSettings.LoskiPotok[1].date = moment(this.stats.lastUpdatedRetje).format('DD. MM. Y HH:mm');
    }

    AddRecordData(StationSettings) {
        StationSettings.RecordSettings.push({min: this.stats.hribMinTemperature, max: this.stats.hribMaxTemperature});
        StationSettings.RecordSettings.push({min: this.stats.retjeMinTemperature, max: this.stats.retjeMaxTemperature});
    }

    ValidateRecordData(StationSettings) {
        let recordData = StationSettings.RecordSettings;
        recordData.forEach(x => {
            if (x.min == null) {
                x.min = '---';
            }
            if (x.max == null) {
                x.max = '---';
            }
        });
    }

    ValidateNullableData() {
        let stats = this.stats;
        if(stats.hribCurrentTemperature == null && stats.retjeCurrentTemperature == null) {

            stats.hribCurrentTemperature = '---';
            stats.hribCurrentHumidity = '---';
            stats.lastUpdatedHrib = '---';

            stats.retjeCurrentTemperature = '---';
            stats.retjeCurrentHumidity = '---';
            stats.lastUpdatedRetje = '---';

            return;
        }

        if(stats.hribCurrentTemperature == null) {
            stats.hribCurrentTemperature = '---';
        }

        if(stats.hribCurrentHumidity == null) {
            stats.hribCurrentHumidity = '---';
        }

        if(stats.lastUpdatedHrib == null) {
            stats.lastUpdatedHrib = '---';
        }

        if(stats.retjeCurrentTemperature == null) {
            stats.retjeCurrentTemperature = '---';
        }

        if(stats.retjeCurrentHumidity == null) {
            stats.retjeCurrentHumidity = '---';
        }

        if(stats.lastUpdatedRetje == null) {
            stats.lastUpdatedRetje = '---';
        }
    }
}

class Original {
    constructor(hribTemperature, hribHumidity, retjeTemperature, retjeHumidity, date) {
        this.hribTemperature = hribTemperature;
        this.hribHumidity = hribHumidity;
        this.retjeTemperature = retjeTemperature;
        this.retjeHumidity = retjeHumidity;
        this.date = date;
    }
}

class Stats {
    constructor(
        hribCurrentTemperature,
        hribCurrentHumidity, 
        hribMaxTemperature, 
        hribMinTemperature, 
        lastUpdatedHrib, 
        retjeCurrentTemperature, 
        retjeCurrentHumidity, 
        retjeMaxTemperature, 
        retjeMinTemperature) {
        
        this.hribCurrentTemperature = hribCurrentTemperature;
        this.hribCurrentHumidity = hribCurrentHumidity;
        this.hribMaxTemperature = hribMaxTemperature;
        this.hribMinTemperature = hribMinTemperature;
        this.lastUpdatedHrib = lastUpdatedHrib;
        this.retjeCurrentTemperature = retjeCurrentTemperature;
        this.retjeCurrentHumidity = retjeCurrentHumidity;
        this.retjeMaxTemperature = retjeMaxTemperature;
        this.retjeMinTemperature = retjeMinTemperature;
    }
}

export {
    LoskiPotok,
    Original,
    Stats
}