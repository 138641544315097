import moment from 'moment';
import { AddDailyTimeSeries } from '../Utils/Stats/Time/AddTimeSeries';

class Gaspinovo {
    constructor() {
        this.details = [];
        this.stats = {};
    }

    PrepareDetails(detailsArray) {
        detailsArray.forEach(x => {
            var details = new Details(
                x.temperature,
                x.dewpoint,
                x.humidity,
                x.windSpeed,
                x.windGust,
                x.windDirection,
                x.rainTotal,
                x.solarRadiation,
                x.updated
            )
            this.details.push(details);
        });
    }

    PrepareGeneral(generalData) {
        var general = new General(
            generalData.altitude,
            generalData.stationType,
            generalData.unit
        )
        this.general = general;
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.currentTemperature,
            statsData.currentHumidity,
            statsData.lastUpdated,
            statsData.avgDewpoint,
            statsData.avgHumidity,
            statsData.avgTemperature,
            statsData.avgWindSpeed,
            statsData.lastHourRain,
            statsData.maxDewpoint,
            statsData.maxDewpointTime,
            statsData.maxHumidity,
            statsData.maxHumidityTime,
            statsData.maxSolarRadiation,
            statsData.maxSolarRadiationTime,
            statsData.maxTemperature,
            statsData.maxTemperatureTime,
            statsData.maxWindGust,
            statsData.maxWindGust,
            statsData.minDewpoint,
            statsData.minDewpointTime,
            statsData.minHumidity,
            statsData.minHumidityTime,
            statsData.minTemperature,
            statsData.minTemperatureTime
        )
        this.stats = stats;
    }

    PrepareUnits(unitsData) {
        var units = new Units(
            unitsData.dewpointUnit,
            unitsData.humidityUnit,
            unitsData.pressureUnit,
            unitsData.rainUnit,
            unitsData.solarObservationUnit,
            unitsData.temperatureUnit,
            unitsData.windUnit
        )
        this.units = units;
    }

    AddChartData(StationSettings) {
        var slemena = { temp: [], date: [], hum: [] };

        slemena.temp = this.details.map(x => x.temperature);
        slemena.hum = this.details.map(x => x.humidity);
        slemena.date = this.details.map(x => moment(x.updated).utc());

        StationSettings.ChartSettings.Temperature.xAxis.categories = AddDailyTimeSeries();
        
        return slemena;
    }

    AddChartProperties(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].name = 'Izver spodaj';
        StationSettings.ChartSettings.Temperature.series[0].color = '#00BFFF';
        StationSettings.ChartSettings.Temperature.series[1].name = 'Izver na vrhu';
        StationSettings.ChartSettings.Temperature.series[1].color = '#00008B';
    }

    AddTableData(StationSettings) {
        StationSettings.TableSettings.Ribnica[2].temp = this.stats.currentTemperature;
        StationSettings.TableSettings.Ribnica[2].date = moment(this.stats.lastUpdated).format('DD. MM. Y HH:mm');
        StationSettings.TableSettings.Ribnica[2].hum = this.stats.currentHumidity;
    }


    ValidateNullableData(StationSettings) {
        if (this.stats.currentTemperature == null) {
            StationSettings.TableSettings.Sodrazica[2].temp = '---';
        }

        if (this.stats.currentHumidity == null) {
            StationSettings.TableSettings.Sodrazica[2].hum = '---';
        }

        if (this.stats.lastUpdated == null) {
            StationSettings.TableSettings.Sodrazica[2].date = '---';
        }
    }
}

class Details {
    constructor(temperature, dewpoint, humidity, windSpeed, windGust, windDirection, rainTotal, solarRadiation, updated) {
        this.temperature = temperature;
        this.dewpoint = dewpoint;
        this.humidity = humidity;
        this.windSpeed = windSpeed;
        this.windGust = windGust;
        this.windDirection = windDirection;
        this.rainTotal = rainTotal;
        this.solarRadiation = solarRadiation;
        this.updated = updated;
    }
}

class General {
    constructor(altitude, stationType, unit) {
        this.altitude = altitude;
        this.stationType = stationType;
        this.unit = unit;
    }
}

class Stats {
    constructor(
        currentTemperature,
        currentHumidity,
        lastUpdated,
        avgDewpoint,
        avgHumidity,
        avgTemperature,
        avgWindSpeed,
        lastHourRain,
        maxDewpoint,
        maxDewpointTime,
        maxHumidity,
        maxHumidityTime,
        maxSolarRadiation,
        maxSolarRadiationTime,
        maxTemperature,
        maxTemperatureTime,
        maxWindGust,
        maxWindGustTime,
        minDewpoint,
        minDewpointTime,
        minHumidity,
        minHumidityTime,
        minTemperature,
        minTemperatureTime
    ) 
    {
        this.currentTemperature = currentTemperature;
        this.currentHumidity = currentHumidity;
        this.lastUpdated = lastUpdated;
        this.avgDewpoint = avgDewpoint;
        this.avgHumidity = avgHumidity;
        this.avgTemperature = avgTemperature;
        this.avgWindSpeed = avgWindSpeed;
        this.lastHourRain = lastHourRain;
        this.maxDewpoint = maxDewpoint;
        this.maxDewpointTime = maxDewpointTime;
        this.maxHumidity = maxHumidity;
        this.maxHumidityTime = maxHumidityTime;
        this.maxSolarRadiation = maxSolarRadiation;
        this.maxSolarRadiationTime = maxSolarRadiationTime;
        this.maxTemperature = maxTemperature;
        this.maxTemperatureTime = maxTemperatureTime;
        this.maxWindGust = maxWindGust;
        this.maxWindGustTime = maxWindGustTime;
        this.minDewpoint = minDewpoint;
        this.minDewpointTime = minDewpointTime;
        this.minHumidity = minHumidity;
        this.minHumidityTime = minHumidityTime;
        this.minTemperature = minTemperature;
        this.minTemperatureTime = minTemperatureTime;
    }
}

class Units {
    constructor(dewpointUnit, humidityUnit, pressureUnit, rainUnit, solarObservationUnit, temperatureUnit, windUnit) {
        this.dewpointUnit = dewpointUnit;
        this.humidityUnit = humidityUnit;
        this.pressureUnit = pressureUnit;
        this.rainUnit = rainUnit;
        this.solarObservationUnit = solarObservationUnit;
        this.temperatureUnit = temperatureUnit;
        this.windUnit = windUnit;
    }
}

export {
    Gaspinovo,
    Details,
    General,
    Stats,
    Units
}