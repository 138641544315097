import moment from 'moment';
import { DegToText } from '../Utils/Convert/DegreesToText';
import { AddDailyTimeSeries } from '../Utils/Stats/Time/AddTimeSeries';

class Sodrazica {
    constructor() {
        this.details = [];
        this.general = {};
        this.stats = {}
        this.units = {};
    }

    PrepareDetails(detailsArray) {
        detailsArray.forEach(x => {
            var details = new Details(
                x.temperature,
                x.dewpoint,
                x.humidity,
                x.windSpeed,
                x.windGust,
                x.windDirection,
                x.rainTotal,
                x.solarRadiation,
                x.updated
            )
            this.details.push(details);
        });
    }

    PrepareGeneral(generalData) {
        var general = new General(
            generalData.altitude,
            generalData.stationType,
            generalData.unit
        )
        this.general = general;
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.currentTemperature,
            statsData.currentHumidity,
            statsData.lastUpdated,
            statsData.avgDewpoint,
            statsData.avgHumidity,
            statsData.avgTemperature,
            statsData.avgWindSpeed,
            statsData.lastHourRain,
            statsData.maxDewpoint,
            statsData.maxDewpointTime,
            statsData.maxHumidity,
            statsData.maxHumidityTime,
            statsData.maxSolarRadiation,
            statsData.maxSolarRadiationTime,
            statsData.maxTemperature,
            statsData.maxTemperatureTime,
            statsData.maxWindGust,
            statsData.maxWindGust,
            statsData.minDewpoint,
            statsData.minDewpointTime,
            statsData.minHumidity,
            statsData.minHumidityTime,
            statsData.minTemperature,
            statsData.minTemperatureTime
        )
        this.stats = stats;
    }

    PrepareUnits(unitsData) {
        var units = new Units(
            unitsData.dewpointUnit,
            unitsData.humidityUnit,
            unitsData.pressureUnit,
            unitsData.rainUnit,
            unitsData.solarObservationUnit,
            unitsData.temperatureUnit,
            unitsData.windUnit
        )
        this.units = units;
    }

    RemovedInvalidChartSeries(StationSettings) {
        if (StationSettings.ChartSettings.Temperature.series.length > 2) {
            StationSettings.ChartSettings.Temperature.series.shift();
        }
    }

    AddChartData(izverSpodaj, izverZgoraj, StationSettings) {
        var sodrazica = { izverSpodaj: { temp: [], date: [], hum: [], wind: null, dir: null }, izverZgoraj: { temp: [], date: [], hum: [], wind: null, dir: null } };

        sodrazica.izverSpodaj.temp = izverSpodaj.details.map(x => x.temperature);
        sodrazica.izverSpodaj.hum = izverSpodaj.details.map(x => x.humidity);
        sodrazica.izverSpodaj.date = izverSpodaj.details.map(x => moment(x.updated).format('YYYY MM DD HH:mm'));
        sodrazica.izverSpodaj.dir = izverSpodaj.details.map(x => x.windDirection);

        sodrazica.izverZgoraj.temp = izverZgoraj.details.map(x => x.temperature);
        sodrazica.izverZgoraj.hum = izverZgoraj.details.map(x => x.humidity);
        sodrazica.izverZgoraj.date = izverZgoraj.details.map(x => moment(x.updated).format('YYYY MM DD HH:mm'));
        sodrazica.izverZgoraj.dir = izverZgoraj.details.map(x => x.windDirection);

        StationSettings.ChartSettings.Temperature.xAxis.categories = AddDailyTimeSeries();
        
        StationSettings.ChartSettings.Temperature.series[1].data = sodrazica.izverSpodaj.temp;
        StationSettings.ChartSettings.Temperature.series[0].data = sodrazica.izverZgoraj.temp;

        return sodrazica;
    }

    AddChartProperties(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].name = 'Izver spodaj';
        StationSettings.ChartSettings.Temperature.series[0].color = '#00BFFF';
        StationSettings.ChartSettings.Temperature.series[1].name = 'Izver na vrhu';
        StationSettings.ChartSettings.Temperature.series[1].color = '#00008B';
    }

    AddTableData(StationSettings, sodrazica, izverZgoraj, izverSpodaj) {
        StationSettings.TableSettings.Sodrazica[0].temp = izverZgoraj.stats.currentTemperature;
        StationSettings.TableSettings.Sodrazica[0].date = izverZgoraj.stats.lastUpdated;
        StationSettings.TableSettings.Sodrazica[0].wind = izverZgoraj.stats.maxWindGust;
        StationSettings.TableSettings.Sodrazica[0].dir = DegToText(sodrazica.izverZgoraj.dir[sodrazica.izverZgoraj.dir.length - 1]);
        StationSettings.TableSettings.Sodrazica[0].hum = izverZgoraj.stats.currentHumidity;
        StationSettings.TableSettings.Sodrazica[1].temp = izverSpodaj.stats.currentTemperature;
        StationSettings.TableSettings.Sodrazica[1].date = izverSpodaj.stats.lastUpdated;
        StationSettings.TableSettings.Sodrazica[1].wind = izverSpodaj.stats.maxWindGust;
        StationSettings.TableSettings.Sodrazica[1].dir = DegToText(sodrazica.izverSpodaj.dir[sodrazica.izverSpodaj.dir.length - 1]);
        StationSettings.TableSettings.Sodrazica[1].hum = izverSpodaj.stats.currentHumidity;
    }

    AddTitleDate(StationSettings, sodrazica) {
        var titleDate = sodrazica.izverSpodaj.date;
        StationSettings.TableSettings.Sodrazica[0].titleDate = moment(titleDate[titleDate.length - 1]).format('DD. MM. ob HH:mm');
    }

    ValidateNullableData(StationSettings, izverZgoraj, izverSpodaj) {
        if (izverZgoraj.stats.currentTemperature == null) {
            StationSettings.TableSettings.Sodrazica[0].temp = '---';
        }

        if (izverZgoraj.stats.currentHumidity == null) {
            StationSettings.TableSettings.Sodrazica[0].hum = '---';
        }

        if (izverZgoraj.stats.lastUpdated == null) {
            StationSettings.TableSettings.Sodrazica[0].date = '---';
        }

        if (izverSpodaj.stats.currentTemperature == null) {
            StationSettings.TableSettings.Sodrazica[1].temp = '---';
        }

        if (izverSpodaj.stats.currentHumidity == null) {
            StationSettings.TableSettings.Sodrazica[1].hum = '---';
        }

        if (izverSpodaj.stats.lastUpdated == null) {
            StationSettings.TableSettings.Sodrazica[1].date = '---';
        }
    }
}

class Details {
    constructor(temperature, dewpoint, humidity, windSpeed, windGust, windDirection, rainTotal, solarRadiation, updated) {
        this.temperature = temperature;
        this.dewpoint = dewpoint;
        this.humidity = humidity;
        this.windSpeed = windSpeed;
        this.windGust = windGust;
        this.windDirection = windDirection;
        this.rainTotal = rainTotal;
        this.solarRadiation = solarRadiation;
        this.updated = updated;
    }
}

class General {
    constructor(altitude, stationType, unit) {
        this.altitude = altitude;
        this.stationType = stationType;
        this.unit = unit;
    }
}

class Stats {
    constructor(
        currentTemperature,
        currentHumidity,
        lastUpdated,
        avgDewpoint,
        avgHumidity,
        avgTemperature,
        avgWindSpeed,
        lastHourRain,
        maxDewpoint,
        maxDewpointTime,
        maxHumidity,
        maxHumidityTime,
        maxSolarRadiation,
        maxSolarRadiationTime,
        maxTemperature,
        maxTemperatureTime,
        maxWindGust,
        maxWindGustTime,
        minDewpoint,
        minDewpointTime,
        minHumidity,
        minHumidityTime,
        minTemperature,
        minTemperatureTime
    ) 
    {
        this.currentTemperature = currentTemperature;
        this.currentHumidity = currentHumidity;
        this.lastUpdated = lastUpdated;
        this.avgDewpoint = avgDewpoint;
        this.avgHumidity = avgHumidity;
        this.avgTemperature = avgTemperature;
        this.avgWindSpeed = avgWindSpeed;
        this.lastHourRain = lastHourRain;
        this.maxDewpoint = maxDewpoint;
        this.maxDewpointTime = maxDewpointTime;
        this.maxHumidity = maxHumidity;
        this.maxHumidityTime = maxHumidityTime;
        this.maxSolarRadiation = maxSolarRadiation;
        this.maxSolarRadiationTime = maxSolarRadiationTime;
        this.maxTemperature = maxTemperature;
        this.maxTemperatureTime = maxTemperatureTime;
        this.maxWindGust = maxWindGust;
        this.maxWindGustTime = maxWindGustTime;
        this.minDewpoint = minDewpoint;
        this.minDewpointTime = minDewpointTime;
        this.minHumidity = minHumidity;
        this.minHumidityTime = minHumidityTime;
        this.minTemperature = minTemperature;
        this.minTemperatureTime = minTemperatureTime;
    }
}

class Units {
    constructor(dewpointUnit, humidityUnit, pressureUnit, rainUnit, solarObservationUnit, temperatureUnit, windUnit) {
        this.dewpointUnit = dewpointUnit;
        this.humidityUnit = humidityUnit;
        this.pressureUnit = pressureUnit;
        this.rainUnit = rainUnit;
        this.solarObservationUnit = solarObservationUnit;
        this.temperatureUnit = temperatureUnit;
        this.windUnit = windUnit;
    }
}

export {
    Sodrazica,
    Details,
    General,
    Stats,
    Units
}